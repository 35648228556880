import type { FC } from 'react'

import type { ExtendBlocksType } from '@Types/blocks/blocks'
import type { MobileAppsLinksType } from '@Types/blocks/mobileAppsLinksType'
import MobileAppDownload from '@components/MobileAppDownload'

import { ApplicationLinksContainer, BackgroundImage } from './styles'

type Props = {
  data: ExtendBlocksType
}

export const ApplicationLinks: FC<Props> = ({ data }) => {
  const { app_store_image, app_store_link, background_image, body, google_store_link, qr_code, svg_icon } =
    data as MobileAppsLinksType

  return (
    <ApplicationLinksContainer data-block={data.type}>
      {background_image && background_image?.uri?.url && (
        <BackgroundImage
          alt="background"
          height={160}
          loading="lazy"
          quality={1}
          src={background_image?.uri?.url}
          width={500}
        />
      )}
      <MobileAppDownload
        appStoreImageUrl={app_store_image?.url}
        appStoreLink={app_store_link?.uri}
        googleStoreImageUrl={svg_icon?.url}
        googleStoreLink={google_store_link?.uri}
        qrUrl={qr_code?.uri?.url}
        title={body?.value}
      />
    </ApplicationLinksContainer>
  )
}
